import React, { useState, useEffect } from "react";
import Arrow from "../arrow";

//Hook qui permet de récupérer la position
function usePosition() {
  const [position, setPosition] = useState();

  useEffect(() => {
    if('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./_service-worker.js');
      }
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (laPosition) => {
          setPosition(laPosition);
        },
        (err) => {
          console.log("erreur: ", err);
        },
        { enableHighAccuracy: true, maximumAge: 100, timeout: 500000 }
      );
    }
  }, []);
  return position;
}

const Pied = () => {
  const [enCours, setEnCours] = useState(false);
  const [arret, setArret] = useState("");
  const position = usePosition();
  const [distanceTrajet, setDistanceTrajet] = useState();

  function distance(lat1, lon1, lat2, lon2) {
    var R = 6378.137;
    var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.floor(d * 1000);
  }

  //function qui perlet de faire vibrer le téléphone
  function vibrate(...ms) {
    window.navigator.vibrate(...ms);
    return new Promise((resolve) => {
      setTimeout(resolve(), ...ms.reduce((pre, curr) => pre + curr, 100));
    });
  }

  const arrets = {
    stops: [
      { name: "Maker Lab", Latitude: 50.601656, Longitude: 3.510695 },
      { name: "Point info", Latitude: 50.601709, Longitude: 3.510828 },
      { name: "Arbre", Latitude: 50.601705, Longitude: 3.510973 },
      { name: "<HITW/>", Latitude: 50.601658, Longitude: 3.510825 },
    ],
  };

  useEffect(() => {
    if (enCours) {
      const stop = arrets.stops.find((spot) => spot.name === arret);
      if (stop === undefined) return;

      if(position){
        setDistanceTrajet(
            distance(
              position.coords.latitude,
              position.coords.longitude,
              stop.Latitude,
              stop.Longitude
            )
          );
    
      }
      
      if (distanceTrajet <= 20) {
        if (distanceTrajet <= 7) {
          navigator.vibrate(0);
          vibrate(4000).then(() => {
          });
        } else if (distanceTrajet >= 15) {
          navigator.vibrate(0);
          vibrate([100, 30, 100]).then(() => {
          });
        } else if (distanceTrajet >= 7) {
          navigator.vibrate(0);
          vibrate([800, 30, 800]).then(() => {
          });
        }
      }
    }
  }, [enCours, position, arrets.stops, arret, distanceTrajet]);

  const handleArret = (e) => {
    if (e.target.value) {
      setArret(e.target.value);
    }
  };

  function handleEnCoursTrue() {
    if (arret === undefined || arret === "") return;
    setEnCours(true);
  }

  function handleEnCoursFalse() {
    if (arret === undefined || arret === "") return;
    setEnCours(false);
  }

  return (
    <div>
      <Arrow />

      <div className="container">
        <div>
          {!enCours && (
            <h1 className="title-1">Veuillez choisir votre arrêt</h1>
          )}
          <div className="choice-stops">
            <svg
              className="choise-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
            </svg>
            <input list="stops" id="search" onChange={handleArret} />
          </div>

          <datalist id="stops">
            {arrets.stops.map((arret, i) => (
              <option value={arret.name} key={i} />
            ))}
          </datalist>
        </div>
        <div className="start-button">
            {
                !enCours ? <button onClick={handleEnCoursTrue}>Commencer</button> : <button onClick={handleEnCoursFalse}>Annuler</button>
            }
          
          
        </div>
        {
            distanceTrajet && enCours ? <span className="distance">{distanceTrajet}m</span> : (false) 

        }
      </div>
    </div>
  );
};

export default Pied;
