import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TransportBtn from "../transportButton";
import "../style/adapt.css";

const Adapt = () => {
  const [transport, setTransport] = useState(true);

  function handleTransport() {
    setTransport(!transport);
  }

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("./_service-worker.js");
    }
  });

  return (
    <>
      <div className="logo"></div>
      <div className="box-transport-method">
        <span onClick={handleTransport}>
          <Link to="/pied">
            <TransportBtn
              imgs={"/icons/person-solid.svg"}
              bgColor={"linear-gradient(#fd626c,#F83340)"}
            />
          </Link>
        </span>
        <span>
          <Link to="/bus">
            <TransportBtn
              imgs={"/icons/bus-solid.svg"}
              bgColor={"linear-gradient(#FFE046,#ffc600)"}
            />
          </Link>
        </span>
        <span>
          <TransportBtn
            imgs={"/icons/location-dot-solid.svg"}
            bgColor={"linear-gradient(#30D680,#00c94c)"}
          />
        </span>
      </div>
    </>
  );
};

export default Adapt;
