import React, { useState } from 'react';
import "../style/transportButton.css"

const TransportBtn = (props) => {
    const [imgs, setImgs] = useState(props.imgs)
    const [bgColor, setBgColor] = useState(props.bgColor)

    const btnStyle = {
        background: bgColor
    }
    return (
        <div className='icon' style={btnStyle}>
            <img src={`${imgs}`} alt={imgs} />
        </div>
    );
};

export default TransportBtn;