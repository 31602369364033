import React from 'react';
import { Link } from "react-router-dom";

const Arrow = () => {
    return (
        <div>
            <Link to='/'><img className="backArrow" src="/icons/arrow.svg" alt="" /></Link>
        </div>
    );
};

export default Arrow;