import React, { useEffect } from 'react';
import Arrow from "../arrow";

const Bus = () => {
    useEffect(()=>{
        if('serviceWorker' in navigator) {
            navigator.serviceWorker.register('./_service-worker.js');
          }
    })
    return (
        <div>
            <Arrow />
            Cette page est la page avec un rendu visuel pour les personnes normale
            
        </div>
    );
};

export default Bus;