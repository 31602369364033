// import './App.css';
import { useEffect } from 'react';
import Route from './components/routes'
import "./components/style/variable.css"



function App() {
  useEffect(()=>{
    if('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./_service-worker.js');
    }
  }, [])
  return (
    <div className="App">
        <Route/>
    </div>
  );
}

export default App;
