import React from "react";
//ajouter Link pour des liens
import { Routes, Route } from "react-router-dom";

import Adapt from "../pages/Adapt";
import Bus from "../pages/bus";
// import Stop from "../pages/stop";
import Pied from "../pages/pied";

const index = () => {
  return (
    <div className="app-routes">
      <Routes>
        <Route path="/" element={<Adapt />} />
        <Route path="/index.html" element={<Adapt />} />
        <Route path="/bus" element={<Bus />} />
        {/* <Route path="/stop" element={<Stop />} /> */}
        <Route path="/pied" element={<Pied />} />
      </Routes>
    </div>
  );
};

export default index;
